import React,{useEffect, useRef, useState} from "react";
import gsap from 'gsap';
import track from '../sound/music.mp3';
import detect from '../utils/detect';

const SoundPlayer = () => {
    const player = useRef(null);
    const isTransition = useRef(false);
    const [isPlaying, setIsPlaying] = useState(null);

    useEffect(() => {

        if(detect.isMobile() || detect.isSafari()) return;
        window.addEventListener('dragComplete', () => {
            setIsPlaying(true);
        });

        return () => {
            window.removeEventListener('dragComplete', () => {
                setIsPlaying(true);
            });
        }
    },[])

    useEffect(() => {

        if(isPlaying === null) return;
        
        if(isPlaying) {
            play();
        } else {
            pause();
        }
    },[isPlaying]);

    const play = () => {
        player.current.play();

        gsap.fromTo(player.current, {
            volume: 0,
        },{
            volume: 0.2,
            duration:1.5,
        });
    }

    const pause = () => {
    
        gsap.fromTo(player.current, {
            volume: 0.2,
        },{
            volume: 0,
            duration:1.5, 
            onComplete: () => {
                player.current.pause();
            }
        });
    }

    return (
        <>
        <audio ref={player} src={track} loop={true} className="hidden"></audio>
        <div className="is-safari-hide">
            <div className="w-4 h-auto flex-shrink-0 is-mobile-hide" cursor="active" onClick={() => setIsPlaying(!isPlaying)}>
                {isPlaying ? 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 10">
                        <g fill="none" fillRule="evenodd">
                            <path stroke="#FFF" d="M16 10V0M12 10V0"/>
                            <path fill="#FFF" d="M0 2.3730264v5.00513457h4.70565404L7.4662722 10H8V0h-.5337278L4.61092554 2.3730264H.21023926z"/>
                        </g>
                    </svg>
                : 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 10">
                        <g fill="none" fillRule="evenodd">
                            <path stroke="#FFF" d="M11 5h7"/>
                            <path fill="#FFF" d="M0 2.3730264v5.00513457h4.70565404L7.4662722 10H8V0h-.5337278L4.61092554 2.3730264H.21023926z"/>
                        </g>
                    </svg>
                }
            </div>
        </div>
        </>
    )
}

export default SoundPlayer;