import React,{useEffect, useRef} from "react";
import {hasAttrInTree, lerp} from '../utils';
import detect from '../utils/detect';
import gsap from 'gsap';

const Cursor = () => {
    const cursorWrapper = useRef(null);
    const cursorRing = useRef(null);
    const cursorDot = useRef(null);
    const arrowLeft = useRef(null);
    const arrowRight = useRef(null);
    const explore = useRef(null);
    const acf = useRef(null);

    const duration = 0.25;
    const ease = 'expo.inOut';

    const mouse = useRef({
      cx:0,
      cy:0,
      tx:0,
      ty:0,
      vx:0,
      vy:0,
      element: null,
      hasMoved: false,
      state: '',
    });
  
    useEffect(() => {

        if(detect.isMobile()) {
            cursorWrapper.current.style.display = 'none';
            return;
        }
  
        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseDrag', mousemove);
        acf.current = requestAnimationFrame(update);

        updateState('defualt', true);
        setTimeout(() => {
            cursorWrapper.current.style.display = 'block';
        },500);
        
        return () => {
            window.removeEventListener('mousemove', mousemove);
            cancelAnimationFrame(acf.current);
        }
    }, []);

    const mousemove = (e) => {

        if(e.detail) {
            e = e.detail.event;
        }
        mouse.current.tx = e.clientX;
        mouse.current.ty = e.clientY;
    
        if(!mouse.current.hasMoved) {
          mouse.current.hasMoved = true;
        }
      }
    
      const update = () => {
        
        mouse.current.cx = lerp(mouse.current.cx, mouse.current.tx,0.5);
        mouse.current.cy = lerp(mouse.current.cy, mouse.current.ty,0.5);
        mouse.current.vx =+ (mouse.current.cx - mouse.current.tx) / 1280;
        mouse.current.vy =+ (mouse.current.cy - mouse.current.ty) / 1280;
        mouse.current.element = document.elementFromPoint(mouse.current.cx,mouse.current.cy);

        cursorWrapper.current.style.transform = `translate3d(${mouse.current.tx - cursorWrapper.current.clientWidth/2}px,${ mouse.current.ty - cursorWrapper.current.clientHeight/2}px,0)`;
        
        if(hasAttrInTree(mouse.current.element, 'cursor')) {
            var cursorElm = hasAttrInTree(mouse.current.element, 'cursor');
            var cursorState = cursorElm.getAttribute('cursor');
            updateState(cursorState);
        } else {
            updateState('default');
        }
        acf.current = requestAnimationFrame(update);
      }
    

    const updateState = (state, instant) => {
        if(state !== mouse.current.state) {
            mouse.current.state = state;
        
            if(mouse.current.state == 'default') {
                setDefaultState(instant);
            } else if(mouse.current.state == 'active') {
                setActiveState(instant);
            }
            else if(mouse.current.state == 'drag') {
                setDragState(instant);
            }

            else if(mouse.current.state == 'explore') {
                setExploreState(instant);
            }
        }
    }

    const setDefaultState = (instant) => {
    
        gsap.to(explore.current,{
            opacity:0,
            duration: instant ?  0 : duration,
            ease: ease
        });


        gsap.to(arrowLeft.current,{
            opacity:0,
            x: -40,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(arrowRight.current,{
            opacity:0,
            x: 40,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to([cursorRing.current],{
            width: '1.625rem',
            height: '1.625rem',
            opacity:1,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to([cursorDot.current],{
            scale:1,
            opacity:1,
            duration: instant ?  0 : duration,
            ease: ease
        });
    }

    const setActiveState = (instant) => {
    
        gsap.to([cursorRing.current],{
            width: '2.25rem',
            height: '2.25rem',
            opacity:1,
            duration: instant ?  0 : duration,
            ease: ease
        });


        gsap.to(cursorDot.current,{
            scale:0.75,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(explore.current,{
            opacity:0,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(arrowLeft.current,{
            opacity:0,
            x: -40,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(arrowRight.current,{
            opacity:0,
            x: 40,
            duration: instant ?  0 : duration,
            ease: ease
        });
    }

    const setDragState = (instant) => {

        gsap.to([cursorRing.current],{
            width: '1.625rem',
            height: '1.625rem',
            opacity:1,
            duration: instant ?  0 : duration,
            ease: ease
        });
        
        gsap.to(cursorDot.current,{
            scale:0,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to([arrowLeft.current,arrowRight.current],{
            opacity:1,
            x: 0,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(explore.current,{
            opacity:0,
            duration: instant ?  0 : duration,
            ease: ease
        });
    }

    const setExploreState = (instant) => {

        gsap.to([cursorRing.current],{
            width: '5.25rem',
            height: '5.25rem',
            opacity:0.5,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(explore.current,{
            opacity:1,
            duration: instant ?  0 : duration,
            ease: ease
        });
        
        gsap.to(cursorDot.current,{
            scale:0,
            duration: instant ?  0 : duration,
            ease: ease
        });

        gsap.to(arrowLeft.current,{
            opacity:0,
            x: -40,
            duration: instant ? 0 : duration,
            ease: ease
        });

        gsap.to(arrowRight.current,{
            opacity:0,
            x: 40,
            duration: instant ? 0 : duration,
            ease: ease
        });
    }

    
    return (
        <div ref={cursorWrapper} className="fixed z-50 w-7 h-7 pointer-events-none hidden">
            <div ref={arrowLeft} className="arrow-left absolute left-0 mt-2 -ml-4"></div>
            <div ref={arrowRight} className="arrow-right absolute right-0 mt-2 -mr-4"></div>
            <div className="absolute m-auto top-0 left-0 right-0 bottom-0 flex justify-center items-center">
                <div ref={cursorRing} className="border border-white rounded-full flex-shrink-0"></div>
            </div>
            <div ref={cursorDot} className="absolute m-auto top-0 left-0 right-0 bottom-0 w-2 h-2 bg-white rounded-full"></div>
            <div ref={explore} className="absolute m-auto top-0 left-0 bottom-0 w-full h-3 text-center flex justify-center">
                <div className="text-xxs">
                    Explore
                </div>
            </div>
        </div>
    )   
}

export default Cursor
