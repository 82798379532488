import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link';
import PropTypes from "prop-types"
import SoundPlayer from '../components/soundPlayer';

const Header = ({ location }) => {

    return (
      <header className="fixed top-0 left-0 w-full z-20 h-12 text-xs">
          <div className="container w-full h-full flex justify-between items-center">
            <ul className="flex">
              <li className="mr-6">
                <TransitionLink to="/info" activeClassName="is-active" className="header-link hover:text-yellow" exit={{length: 1}} entry={{ delay: 0.8 }} cursor="active">Info</TransitionLink>
              </li>
              <li><TransitionLink to="/" activeClassName="is-active" className="header-link hover:text-yellow" cursor="active" exit={{length: 1}} entry={{ delay: 0.8 }}>Overview</TransitionLink></li>
            </ul>
            <div>
            <div className="flex items-center">
              <SoundPlayer />
              <a href="https://www.kontrapunkt.com/" className="hover:text-yellow transition duration-500 ease-in-out ml-5" target="blank" cursor="active">Kontrapunkt</a>
            </div>
            </div>
          </div>
          <div className="absolute bottom-0 w-full h-px bg-white opacity-25"></div>
      </header>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
