import React, {useRef, useEffect} from "react";
import gsap from 'gsap';
import SplitText from "../utils/gsap-shockingly-green/src/SplitText";
import {clamp,lerp} from '../utils';
import detect from '../utils/detect';
import Ddd from './logos/ddd';
import Ggg from './logos/ggg';

const Wrapper = ({children, forwardedRef}) => {
    return (
        <div  className="fixed top-0 left-0 w-full h-full z-50 select-none">
            <div className="absolute w-full h-full bg-black"></div>
            <div ref={forwardedRef} className="absolute w-full h-full opacity-0 translateZ perspective">
                {children}
            </div>
        </div>
    )
}

const Intro = ({forwardedRef}) => {

    const {intro, introProgress, introText, introCredit} = forwardedRef;

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black z-20 md:flex md:items-center invisible" ref={intro}>
            <div className="container text-lg lg:text-2xl leading-snug py-12">
                <div ref={introText} className="md:text-center">
                    <p className="mb-12 is-lang-ja font-ja break-all m-auto">独自の書体の背景には、それぞれのブランドの <br />ユニークなストーリーがあります。各書体の形状や<br />意味を、一緒に探求していきましょう。</p>
                    <p className="mb-12 is-lang-en max-w-4xl m-auto">Behind each bespoke typeface is a brand with a unique story. You are about to explore the form and meaning of Kontrapunkt Type.</p>
                </div>
                <div className="text-gray opacity-0 md:flex md:flex-col md:items-center" ref={introCredit}>
                    <p className="text-xs leading-tight mb-6 max-w-md">Made in collaboration with kyoto ddd gallery &<br  className="md:hidden" /> Ginza Graphic Gallery in Tokyo</p>
                    <div className="flex mr-5 h-full">
                        <div className="mr-3 mb-4 md:mb-0" style={{width: '2.375rem'}}>
                            <Ddd />
                        </div>
                        <div style={{width: '2.0625rem'}}>
                            <Ggg />
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute left-0 bottom-0 w-full h-px mb-16 lg:mb-20">
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-25"></div>
                <div className="absolute top-0 left-0 w-full h-full bg-white transform -translate-x-full" ref={introProgress}></div>
            </div>
        </div>
    )
}
const Letters = ({forwardedRef}) => {
    return (
        <div ref={forwardedRef} className="absolute w-full h-full flex items-center justify-center opacity-0">
            <p className="headline-offset font-goertek text-6xl lg:text-8xl tracking-tighter leading-none mb-16 goertek-animation transform -translate-y-16">
                <span className="goertek-animation-step inline-block">E</span>
                <span className="goertek-animation-smooth inline-block">E</span>
                <span className="goertek-animation-step inline-block">E</span>
            </p>
        </div>
    );
}

const Controls = ({forwardedRef, selectLang}) => {

    const {controlsWrapper ,progressBar, langSelector, progressWrapper, helperText, button} = forwardedRef;
    return (
        <div className="absolute left-0 right-0 w-56 m-auto bottom-0 mb-24" ref={controlsWrapper}>

            <div className="opacity-0 flex items-center w-full flex-col" ref={langSelector}>
                <p ref={helperText} className="text-sm text-center text-gray mb-6">Pick your prefered language</p>

                <div className="flex items-center -mb-4 text-sm">
                    <p className="font-ja cursor-pointer" cursor="active" onClick={() => selectLang('ja')}>日本</p>
                    <span className="w-1 h-1 rounded-full bg-gray mx-4"></span>
                    <p className="cursor-pointer" cursor="active" onClick={() => selectLang('en')}>English</p>
                </div>
            </div>

            <div className="relative w-full h-px overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-25" ref={progressWrapper}></div>
                <div className="absolute top-0 left-0 w-full h-full bg-white transform -translate-x-full" ref={progressBar}></div>
           </div>
           {/*
           <div ref={button} className="absolute top-0 left-0 transform -translate-y-1/2 z-10 -ml-4 opacity-0" cursor="active">
            <div className="relative w-7 h-7 bg-black border border-white rounded-full flex items-center justify-center">
                <div className="absolute right-0 -mr-4 arrow-right"></div>
            </div>
           </div> */}
           {/* <p ref={helperText} className="absolute w-full bottom-0 -mb-12 text-xs text-center opacity-0">Drag right to start the experience</p> */}
        </div>

    )
}

const Counter = ({forwardedRef}) => {

    const {counterWrapper,counter} = forwardedRef;
    return <p ref={counterWrapper} className="absolute left-0 bottom-0 w-full text-center text-xs mb-12"><span ref={counter}>0</span>%</p>
}

const Preloader = () => {
    const wrapper = useRef(null);
    const progressBar = useRef(null);
    const progressWrapper = useRef(null);
    const counterWrapper = useRef(null);
    const counter = useRef(null);
    const letters = useRef(null);
    const helperText = useRef(null);
    const button = useRef(null);
    const intro = useRef(null);
    const introProgress = useRef(null);
    const introText = useRef(null);
    const introCredit = useRef(null);
    const controlsWrapper = useRef(null);
    const langSelector = useRef(null);
    
    const startDrag = useRef(null);
    const currentDrag = useRef(null);
    const acf = useRef(null);
    
    const mouse = useRef({
        cx:0,
        cy:0,
        tx:0,
        ty:0,
        w: 0,
        h: 0 
      });

    useEffect(() => {
        init();

        update();
        resize();

        if(!detect.isMobile()) {
            window.addEventListener('mousemove', mouseMoveGlobal);
        }

        // button.current.addEventListener('mousedown', mouseDown);
        // button.current.addEventListener('touchstart', mouseDown);

        window.addEventListener('resize', resize);
    },[]);

    const mouseMoveGlobal = (e) => {
        
        mouse.current.tx = e.clientX - mouse.current.w/2;
        mouse.current.ty = e.clientY - mouse.current.h/2;
    }

    const update = () => {
        mouse.current.cx = lerp(mouse.current.cx, mouse.current.tx,0.05);
        mouse.current.cy = lerp(mouse.current.cy, mouse.current.ty,0.05);

        var ratio = 150;

        gsap.set(letters.current.children[0],{
            rotateY:mouse.current.cx/ratio,
            x:mouse.current.cx/16,
            rotateX: mouse.current.cy/ratio * -1,
            y:mouse.current.cy/16 * -1
        });

        gsap.set(controlsWrapper.current,{
            rotateY:mouse.current.cx/ratio,
            x:mouse.current.cx/16,
            rotateX: mouse.current.cy/ratio,
            y:mouse.current.cy/16 * -1
        });

        gsap.set(counterWrapper.current,{
            rotateY:mouse.current.cx/ratio,
            x:mouse.current.cx/16,
            rotateX: mouse.current.cy/ratio,
            y:mouse.current.cy/16 * -1
        });

        acf.current = requestAnimationFrame(update);

    }

    const init = () => {
        
        gsap.to(wrapper.current, {
            opacity:1,
            delay:0.2,
            onComplete: () => {
                startProgresss();
            }
        })
    }

    const resize = () => {
        mouse.current.w = window.innerWidth;
        mouse.current.h = window.innerHeight;
        
        // gsap.set(button.current, {
        //     x: progressWrapper.current.clientWidth/2,
        // });
    }

    // const mouseDown = (e) => {
        
    //     startDrag.current = e.clientX || e.targetTouches[0].pageX;

    //     gsap.to(helperText.current, {
    //         opacity:0
    //     });

    //     window.addEventListener('mousemove', mouseMove);
    //     window.addEventListener('touchmove', mouseMove);
    //     window.addEventListener('mouseup', mouseUp);
    //     window.addEventListener('touchend', mouseUp);
    // }

    // const mouseUp = (e) => {
  
      
    //     window.removeEventListener('mousemove', mouseMove);
    //     window.removeEventListener('touchmove', mouseMove);
    //     window.removeEventListener('mouseup', mouseUp);
    //     window.removeEventListener('touchend', mouseUp);

        
    //     if(progressWrapper.current.clientWidth <= clamp(progressWrapper.current.clientWidth/2 + (currentDrag.current - startDrag.current), 0, progressWrapper.current.clientWidth)) {
    //         console.log('leave it');
    //     } else {
    
    //         gsap.to(helperText.current, {
    //             opacity:1
    //         });
            
    //         gsap.to(button.current, {
    //             x: progressWrapper.current.clientWidth/2,
    //         });

    //         gsap.to(letters.current, {
    //             x: 0,
    //             rotationY:0,
    //             opacity:1
    //         });
    //     }
        
    //     startDrag.current = null;
    //     currentDrag.current = 0;
    // }

    // const mouseMove = (e) => {
        
    //     currentDrag.current = e.clientX || e.targetTouches[0].pageX;

    //     gsap.set(button.current, {
    //         x: clamp(progressWrapper.current.clientWidth/2 + (currentDrag.current - startDrag.current), 0, progressWrapper.current.clientWidth),
    //         overwrite: true,
    //     });

    //     if((progressWrapper.current.clientWidth) <= clamp(progressWrapper.current.clientWidth/2 + (currentDrag.current - startDrag.current), 0, progressWrapper.current.clientWidth)) {
    //         dragComplete();
    //     }
    // }

    const dragComplete = () => {
        
        // window.removeEventListener('mousemove', mouseMove);
        // window.removeEventListener('touchmove', mouseMove);
        // window.removeEventListener('mouseup', mouseUp);
        // window.removeEventListener('touchend mouseup', mouseUp);

        window.dispatchEvent(new CustomEvent('dragComplete'));

        // gsap.to(button.current, {
        //     scale:0,
        //     delay:0.6,
        //     ease: 'back.in(1.7)',
        // });

        // gsap.to(progressWrapper.current, {
        //     xPercent:100,
        // });


        gsap.to(langSelector.current, {
            opacity:0,
            duration:0.8,
        });

        gsap.to(letters.current.children[0].children, {

            opacity:0,
            duration:1.2,
            ease: "circ.inOut",
            onComplete: showIntro,
            stagger: {
                amount:-0.3,
            }
        
        });
    }



    const selectLang = (code) => {

        if(code) {
            console.log('code',code);
            document.querySelector('html').setAttribute('lang', code);
        } else {
            document.querySelector('html').setAttribute('lang', 'en');
        }

        dragComplete();
    }

    const showIntro = () => {

        new SplitText(introText.current.children[0], {type:"lines"});
        new SplitText(introText.current.children[1], {type:"lines"});
        gsap.fromTo(intro.current, {
            opacity:0,
            onStart: () => {
                gsap.set(intro.current, {
                    visibility: 'visible'
                });
            }
        }, {
            opacity:1,
            duration:2,
        });

        gsap.fromTo(introText.current.children[0].children, {
            y:80,
            opacity:0,
        },{
            y:0,
          
            opacity:1,
            duration:1.2,
            delay:0.1,
            ease:"circ.out",
            stagger: {
                amount:0.15,
            },
        });

        gsap.fromTo(introText.current.children[1].children, {
            y:80,
            opacity:0,
        },{
            y:0,
          
            opacity:1,
            duration:1.2,
            delay:0.1,
            ease:"circ.out",
            stagger: {
                amount:0.15,
            },
        });

        gsap.to(introCredit.current, {
            opacity:1,
            delay:0.75,
            duration:1,
            ease: "power2.inOut",
        });

        gsap.to(introProgress.current, {
            x:0,
            delay:0.25,
            duration:4.5,
            ease:"none",
            onComplete: completeIntro
        });
    }

    const completeIntro = () => {

        gsap.to(introCredit.current, {
            opacity:0,
            duration:0.4,
            ease: "power2.inOut",
        });

        gsap.fromTo(introText.current.children[0].children, {
            opacity:1,
        },{
            opacity:0,
            duration:0.8,
            delay:0.1,
            ease:"power2.inOut",
            stagger: {
                amount:-0.15,
            }
        });


        gsap.fromTo(introText.current.children[1].children, {
            opacity:1,
        },{
            opacity:0,
            duration:0.8,
            delay:0.1,
            ease:"power2.inOut",
            stagger: {
                amount:-0.15,
            }
        });

        gsap.to(intro.current, {
            opacity:0,
            delay:1.2,
            onComplete: () => {
                gsap.to(wrapper.current.parentElement, {
                    opacity:0,
                    duration:0.4,
                    onComplete: () => {
                        gsap.set(wrapper.current.parentElement, {
                            display: 'none',
                        });
                        
                        document.querySelector('html').setAttribute('is-loaded', '');
                        window.dispatchEvent(new CustomEvent('loadingDone'));
                        cancelAnimationFrame(acf.current);
                    }
                })
            }
        });
    }

    const startProgresss = () => {

        const count = {
            current: 0
        };

        gsap.to(progressBar.current,{
            x:0,
            duration:1.5
        });

        gsap.to(count,{
            current:100,
            duration:1.5,
            onComplete: progresssEnded,
            onUpdate: () => {
                counter.current.innerHTML = Math.round(count.current);
            }
        });
    }

    const progresssEnded = () => {
    
        gsap.to([counterWrapper.current,progressBar.current,progressWrapper.current],{
            opacity:0,
            duration:0.5,
        });

        gsap.fromTo(letters.current, {
            yPercent: -100,
            rotateX:90,
            opacity:0,
        },{
            yPercent:0,
            rotateX:0,
            opacity:1,
            duration:1,
            clearProps: 'transform',
            ease: "power2.out",
        });

        gsap.fromTo(langSelector.current, {
            opacity:0,
        },{
            opacity:1,
            duration:0.8,
            delay:1,
        });

        // gsap.fromTo(button.current,{
        //     x:0,
        //     opacity:0,
        // }, {
        //     opacity:1,
        //     x: progressWrapper.current.clientWidth/2,
        //     duration:1.4,
        //     delay:1,
        //     ease: "expo.out",
        // });

    }

    return (
        <Wrapper forwardedRef={wrapper}>
            <Letters forwardedRef={letters} />
            <Controls forwardedRef={{
                controlsWrapper,
                progressBar,
                progressWrapper,
                helperText,
                button,
                langSelector
            }} selectLang={selectLang}/>
            <Counter forwardedRef={{
                counter,
                counterWrapper
            }} />
            <Intro forwardedRef={{
                intro,
                introText,
                introCredit,
                introProgress
            }} />
        </Wrapper>
    )
}

export default Preloader;