/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";


// import CookieBanner from './cookieBanner';
import Header from "./header"
import Preloader from './preloader';
import Cursor from '../components/cursor';
import {addDetectClasses} from '../utils/detect';
import "../styles/main.scss";

const Layout = ({ children }) => {

  useEffect(() => {
    addDetectClasses();
  },[])


  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Preloader />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Cursor />
      {/* <CookieBanner/> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
